<template>
  <div>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'mainTitleMobile' : 'mainTitle'">{{ $t('mainTitle') }}</h1>
    </v-row>
    <v-row justify="center" v-bind:class="mobile ? 'sectionMobile' : 'section'" no-gutters>
      <v-col v-bind:cols="mobile ? 10 : 5" class="textSection">
        <p class="textSection1 textGrey" v-html="$t('textSectionLeft1')"></p>
        <p class="textSection1 textBlue" v-html="$t('textSectionLeft2')"></p>
      </v-col>
      <v-col v-if="!mobile" cols="1"></v-col>
      <v-col v-bind:cols="mobile ? 10 : 5" class="textSection">
        <strong><p class="textSection1 textBlue" v-html="$t('textSectionRight1')"></p></strong> <br>

        <strong><span class="textSection1 textBlue">S1 - </span></strong>
        <span class="textSection1 textGrey" v-html="$t('textSectionRight2')"></span> <br>

        <strong><span class="textSection1 textBlue">S2 - </span></strong>
        <span class="textSection1 textGrey" v-html="$t('textSectionRight3')"></span> <br>

        <strong><span class="textSection1 textBlue">S3 - </span></strong>
        <span class="textSection1 textGrey" v-html="$t('textSectionRight4')"></span> <br>

        <strong><span class="textSection1 textBlue">S4 - </span></strong>
        <span class="textSection1 textGrey" v-html="$t('textSectionRight5')"></span> <br>

        <strong><span class="textSection1 textBlue">S5 - </span></strong>
        <span class="textSection1 textGrey" v-html="$t('textSectionRight6')"></span> <br>
      </v-col>
      <v-col v-if="!mobile" cols="1"></v-col>
    </v-row>
    <v-row v-bind:justify="mobile ? 'center' : 'start'" v-bind:class="mobile ? 'sectionMobile' : 'section'" style="padding-bottom: 40px;" no-gutters>
      <v-btn v-bind:class="mobile ? 'contactUsBtnMobile' : 'contactUsBtn'"
             rounded
             height="65px"
             dark
             v-html="$t('contactUs')"
             @click="$router.push({name: 'Contact'}).then(() => {$vuetify.goTo(0)})"
             color="#57939E">
      </v-btn>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'NutritionWorkshopComp',
    props: ['mobile'],
    data: () => ({
    }),
  }
</script>

<i18n>
{
  "en": {
    "mainTitle": "Nutrition workshop : good on your plate",
    "textSectionLeft1": "Nutrition plays a very important role in health. As the body ages, so do nutritional needs. <br> <br> Contrary to popular belief, needs do not decrease with age and some even increase. It would therefore be a mistake to think that as you get older you should eat less, especially for physically active people. <br> On the other hand, it is important to make sure you eat better and be active every day.",
    "textSectionLeft2": "The « good on your plate, at ease in your sneakers » workshop aims to raise awareness, inform and educate the elderly on a diet adapted to nutritional needs and a daily adapted physical activity in order to protect against certain diseases (diabetes , cardiovascular diseases, cancers, etc.), to limit the problems of fatigue, osteoporosis - and thus to reduce the risk of falls and fractures -, to defend against infections and quite simply to age well, while continuing to live according to his wishes.",
    "textSectionRight1": "The « healthy on your plate, at ease in your sneakers » workshop consists of 5 sessions of 2 hours:",
    "textSectionRight2": "General food point, the general functions of food families, the relationship between our diet, our health and aging (Quiz, discussion).",
    "textSectionRight3": "Adapt your diet according to meals taken in the community. How to adapt the diet taken independently according to the meals taken in the community.",
    "textSectionRight4": "The 5 senses and food »: By eating, we engage all of our senses. How to deal with the possible effects of aging?",
    "textSectionRight5": "The health benefits of adapted physical activity: recommendations, benefits and practical advice.",
    "textSectionRight6": "Animation / tasting session on a theme (eg Energy balls) determined according to the needs identified during previous sessions.",
    "contactUs": "Contact us"
  },
  "fr": {
    "mainTitle": "Atelier nutrition : bien dans son assiette",
    "textSectionLeft1": "La nutrition joue un rôle très important sur la santé. En prenant de l’âge, l’organisme change, les besoins nutritionnels aussi. <br><br> Contrairement aux idées reçues, les besoins ne diminuent pas avec l’âge et certains augmentent même. Ce serait donc une erreur de penser qu’en vieillissant il faut manger moins, surtout pour les personnes physiquement actives. <br> En revanche, il est important de veiller à manger mieux et à bouger tous les jours.",
    "textSectionLeft2": "L’atelier « bien dans son assiette, à l’aise dans ses baskets » a pour vocation de sensibiliser, informer et éduquer le public âgé à une alimentation adaptée aux besoins nutritionnels et une activité physique adaptée quotidienne afin de protéger contre certaines maladies (diabète, maladies cardiovasculaires, cancers, etc.), à limiter les problèmes de fatigue, d’ostéoporose – et ainsi à réduire les risques de chutes et de fractures –, à se défendre contre les infections et tout simplement à bien vieillir, tout en continuant à vivre selon ses désirs.",
    "textSectionRight1": "L’atelier « bien dans son assiette, à l’aise dans ses baskets » se compose de 5 séances de 2h :",
    "textSectionRight2": "Point général alimentation, les fonctions générales des familles d’aliments, les relations entre notre alimentation, notre santé et le vieillissement (Quizz, discussion).",
    "textSectionRight3": "Adapter son alimentation en fonction des repas pris en collectivité. Comment adapter l’alimentation prise en autonomie en fonction des repas pris en collectivité ?",
    "textSectionRight4": "« Les 5 sens et l’alimentation » : En mangeant, nous sollicitons tous nos sens. Comment faire face aux possibles effets du vieillissement ?",
    "textSectionRight5": "Les bienfaits de l’activité physique adaptée pour la santé : recommandations, intérêt et conseils pratiques.",
    "textSectionRight6": "Séance d’animation/dégustation sur un thème (ex : Energy balls) déterminé en fonction des besoins identifiés lors des précédentes séances.",
    "contactUs": "Contactez nous"
  }
}
</i18n>

<style scoped>

.textBlue {
  color: #57939E;
}

.textGrey {
  color: #808080;
}

.section {
  padding-left: 200px;
  padding-right: 200px;
}

.sectionMobile {
  font-size: 14px;
  padding-bottom: 30px;
}

@media screen and (max-width: 1500px) {
  .contactUsBtn {
    margin-top: 20px;
    margin-left: 50px;
    font-size: 18px;
    text-transform: none;
    width: 450px;
  }
  .textSection {
    font-size: 14px;
  }
}
@media screen and (min-width: 1500px) {
  .contactUsBtn {
    margin-top: 50px;
    margin-left: 50px;
    font-size: 18px;
    text-transform: none;
    width: 500px;
  }
  .textSection {
    padding-right: 100px;
  }
}

.contactUsBtnMobile {
  font-size: 16px;
  text-transform: none;
  width: 300px;
}

</style>
